<template>
  <div class="index">
    <div class="banner">
      <div class="content">
        <div class="left">
          <div class="title"> Using AI dialogue to practice speaking and cross language boundaries</div>
          <div class="desc">
            <div class="t-desc">
              用&nbsp;<span class="text">AI对话练口语</span>&nbsp;跨越语言边界
            </div>
            <div class="b-desc">100W+外语学习者的共同选择</div>
          </div>
        </div>
        <div class="right">
          <img src="../../assets/images/index/header_ai_video.png" alt="">
        </div>
      </div>
    </div>
    <div class="system">
      <div class="center-slot">
        <img src="../../assets/images/index/content_bg_1.png" class="item-img" />
      </div>
      <div class="right-slot">
        <img src="../../assets/images/index/icon_1.png" class="slot-icon" alt="">
        <div class="title">一对一AI外教陪聊，探索智能AI交流的无限可能!</div>
        <div class="top-desc">作为全球目前最先进的口语AI工具，无论您是初学者还是想提升口语水平，我都能提供24小时的在线陪聊服务。</div>
        <div class="top-desc" style="margin-top: 16px;">我支持多达60多种语言，不管您是想学习英语、德语、日语，还是西班牙语……我都能完美胜任！ </div>
      </div>
    </div>

    <div class="tool">

    </div>

    <!-- 行业 -->
    <div class="industry">

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  .auto {
    max-width: 1200px;
    margin: 0 auto;
  }

  .banner {
    padding: 44px 0 52px 35px;
    background-repeat: no-repeat;
    position: relative;
    background: url(../../assets/images/index/header_bg.png) center no-repeat;
    background-size: cover;

    .content {
      display: flex;
      margin-top: 114px;

      .left {
        .title {
          font-family: Futura;
          font-size: 60px;
          font-weight: 700;
          line-height: 90px;
        }

        .desc {
          .t-desc {
            line-height: 66px;
            font-weight: 500;
            font-size: 50px;

            .text {
              font-family: Futura !important;
              font-weight: 700;
              position: relative;
              z-index: 1;
              display: inline-block;

              &::after {
                content: '';
                position: absolute;
                left: 50%;
                right: 0;
                bottom: 0;
                width: 300px;
                height: 35px;
                margin-left: -150px;
                background-color: #d3ff99;
                z-index: -1;
              }
            }
          }

          .b-desc {
            font-size: 50px;
            margin-top: 6px;
            line-height: 70px;
            font-weight: 600;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
      }
    }
  }

  .system {
    display: flex;
    align-items: center;
    padding: 10px 55px 10px 35px;

    .center-slot {
      margin-right: 74px;
    }

    .right-slot {
      .slot-icon {
        display: block;
        margin: 0 auto;
      }

      .title {
        font-size: 32px;
        font-weight: 500;
        line-height: 50px;
        margin-bottom: 24px;
      }

      .top-desc {
        font-size: 16px;
        line-height: 30px;
        color: #1e4300;
      }
    }

  }

  .tool {}

  .industry {}

}
</style>